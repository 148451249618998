import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["community"],
  "status": ["satelite"],
  "title": "DO:TOPIA / Cybernauterne",
  "subtitle": "Cyber Feministic Hacker Collective",
  "description": "",
  "years": ["2017", "2018", "2019", "2020", "2021", "2022"],
  "institutions": ["cybernauter"],
  "location": "cph",
  "keywords": {
    "topics": ["iot", "cv"],
    "tools": ["rpi", "python"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Founder, Hacktivist, Educator and Creative Coder"
  }, {
    "name": "Maia Lorentzen",
    "role": "Imaginative"
  }, {
    "name": "Nynne",
    "role": "Imaginative"
  }, {
    "name": "Henrik",
    "role": "Imaginative"
  }, {
    "name": "Aslak",
    "role": "Imaginative"
  }, {
    "name": "Laura",
    "role": "Imaginative"
  }, {
    "name": "Julie",
    "role": "Imaginative"
  }, {
    "name": "Stis",
    "role": "Imaginative"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Description`}</h2>
    <p>{`Cyber Feministic Hacker Collective
A community.
Internet activism, the red pill
Tinfoil Hats
An initiative and community where we explorer our digital reality and fight for technological literacy.
Whitehat hacking
Teaching
Events planning and executing
An initiative and community where we explorer our digital reality and fight for tech- nological literacy. By educating people in tech-skills, online etiquette, and politics.
Feminist Cybertechno
Activist Collective
Security and Privacy
Cyber Activism
Educational Events
Feministic Cybertech-Activism
Privacy and Digital Literacy Expert
A Cyberfeministic Hacker Collective
This is an initiative and community where we explorer our digital reality and fight for technological literacy. By educating people in tech-skills, online etiquette, and politics.
This is an initiative aiming to heighten engagement in digital realities, teach tech skills and educate people in online etiquette and politics.
This is an initiative and community where we explorer our digital presence, realities, and fight for technological literacy. By educating people in tech-skills, online etiquette, and politics.`}</p>
    <p>{`We're a collective of tech minded cyberpunks.
The website works as a gateway or portal to our individual projects as well as the ones we've accomplished together.`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Photos`}</li>
      <li parentName="ul">{`Links`}</li>
    </ul>
    <h2>{`Events`}</h2>
    <h3>{`Website “Hacking”`}</h3>
    <h3>{`Hack og Kod`}</h3>
    <h3>{`Aarhus Internet Week`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.information.dk/indland/2017/05/internettet-skraldespand-fyldt-modbydeligheder-unge-undvaere"
        }}>{`Event at Aarhus Internet Week`}</a></li>
      <li parentName="ul">{`NAO (Videos?)`}</li>
    </ul>
    <h3>{`2019 ARKEN Workshop`}</h3>
    <p>{`In 2019, we collaborated with Arken Museum of Modern Art on a series of workshops about manipulation and propaganda reaching 300+ students aged 14-15.
`}<a parentName="p" {...{
        "href": "https://www.arken.dk/arken-sosaetter-projekt-om-manipulation-og-propaganda-en-digital-tidsalder/"
      }}>{`https://www.arken.dk/arken-sosaetter-projekt-om-manipulation-og-propaganda-en-digital-tidsalder/`}</a></p>
    <h4>{`Chatbot Sider`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/The-earth-is-triangular-2300400329980824/"
        }}>{`The Earth is Triangle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/Pigs-body-364509234392989/"
        }}>{`Pigs Body`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/Arken-973705176154107/"
        }}>{`Arken`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/The-Flat-Sun-Society-3161349087268906/"
        }}>{`The Flat Sun Society`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/Arkens-forbandelse-478576622677076/"
        }}>{`Arkens forbandelse`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/Nordkorea-findes-ikke-314319469226438/"
        }}>{`Nordkorea Findes Ikke`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://www.tv2lorry.dk/nyheder/04-03-2019/1730/1730-04-mar-2019?autoplay=1#player"
      }}>{`TV2 Lorry Inslag`}</a></p>
    <h3>{`Enigma`}</h3>
    <p>{`Cyber Security Guide
Easy tricks to secure your online presence.`}</p>
    <h3>{`TalkTown`}</h3>
    <ul>
      <li parentName="ul">{`UnFacerecognition`}</li>
    </ul>
    <h3>{`Krogerup Højskole`}</h3>
    <h3>{`Hack Dine Planter`}</h3>
    <p>{`Workshop about making an automated watering systema`}</p>
    <h3>{`Camp HEX 2018`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.facebook.com/events/238473353371410/"
      }}>{`https://www.facebook.com/events/238473353371410/`}</a></p>
    <h1>{`Check Projects / Events`}</h1>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul">{`VICE`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.vice.com/da/article/3k4y9n/laura-og-maia-vil-laere-dig-at-bruge-humor-mod-trolls-broadly"
            }}>{`Laura og Maia Interview`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.vice.com/da/article/yweeeb/jeg-kommer-til-at-laere-mere-om-programmering-til-jeg-doer-det-elsker-jeg-broadly"
            }}>{`Nynne Interview`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Talk Town Podcast, Aflyttet`}</li>
      <li parentName="ul">{`Katrine Pedersen (Loopland)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      